<template>
  <div class="register-container">
    <h2>Create Account</h2>
    <form @submit.prevent="registerUser" class="register-form">
      <div class="form-group">
        <label for="username">Username</label>
        <input
          v-model="username"
          type="text"
          id="username"
          class="input-field"
          required
        />
      </div>
      <div class="form-group">
        <label for="email">Email</label>
        <input
          v-model="email"
          type="email"
          id="email"
          class="input-field"
          required
        />
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input
          v-model="password"
          type="password"
          id="password"
          class="input-field"
          required
        />
      </div>
      <div class="form-group terms-group">
        <input
          type="checkbox"
          id="agreeTerms"
          v-model="agreeTerms"
          required
        />
        <label for="agreeTerms">
          I agree to the
          <span class="terms-link" @click="showTerms = true">Terms of Service</span>.
        </label>
      </div>
      <button type="submit" class="submit-button">Create Account</button>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      <p v-if="successMessage" class="success-message">{{ successMessage }}</p>
    </form>

    <!-- Terms Modal -->
    <div v-if="showTerms" class="modal-overlay" @click.self="showTerms = false">
      <div class="modal">
        <h2>Terms of Service</h2>
        <div class="terms-content">
          <p><strong>Effective Date:</strong> September 2024</p>

          <h3>1. Acceptance of Terms</h3>
          <p>
            By using 6buoys, you agree to these Terms of Service. You are
            responsible for ensuring that you understand and comply with these
            terms.
          </p>

          <h3>2. Community Guidelines</h3>
          <ul>
            <li><strong>Be Respectful:</strong> Treat other users with respect and courtesy. Feedback should be constructive and focused on helping others improve.</li>
            <li><strong>Prohibited Content:</strong> Do not post videos, comments, or other content that is offensive, abusive, violent, obscene, discriminatory, or otherwise objectionable. Content that promotes illegal activities is strictly prohibited.</li>
            <li><strong>Legal Compliance:</strong> All content must comply with applicable laws and regulations. Do not post content that infringes on the rights of others, including intellectual property rights.</li>
          </ul>

          <h3>3. User Responsibilities</h3>
          <ul>
            <li><strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account information. Do not share your account details with others.</li>
            <li><strong>Content Ownership:</strong> You retain ownership of the content you post, but you grant 6buoys a license to display, share, and distribute your content within the app.</li>
            <li><strong>Content Moderation:</strong> We reserve the right to remove any content that violates our guidelines or terms without notice.</li>
          </ul>

          <h3>4. Violations and Enforcement</h3>
          <p>
            <strong>Content Removal:</strong> If your content violates our terms, it
            will be removed.
          </p>
          <p>
            <strong>Account Suspension:</strong> Repeated or severe violations may
            result in temporary or permanent suspension of your account.
          </p>

          <h3>5. Reporting Violations</h3>
          <p>
            If you encounter any content that violates these terms, please report it
            using the in-app reporting feature.
          </p>

          <h3>6. Changes to the Terms</h3>
          <p>
            We may update these Terms of Service from time to time. Continued use of
            the app after any changes indicates your acceptance of the new terms.
          </p>

          <h3>7. Privacy Policy</h3>
          <p>
            Please review our Privacy Policy to understand how we collect, use, and
            protect your data.
          </p>

          <h3>8. Contact Us</h3>
          <p>
            If you have questions or concerns about these terms, please contact us
            at 6buoysskiing@gmail.com.
          </p>
        </div>
        <button class="close-button" @click="showTerms = false">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      username: '',
      email: '',
      password: '',
      agreeTerms: false,
      errorMessage: '',
      successMessage: '',
      showTerms: false,
    };
  },
  methods: {
    async registerUser() {
      if (!this.agreeTerms) {
        this.errorMessage = 'You must agree to the terms to create an account.';
        return;
      }

      try {
        await axios.post('https://api.6buoys.com/api/create-account/', {
          username: this.username,
          email: this.email,
          password: this.password
        });

        this.successMessage = 'Account created successfully!';
        this.errorMessage = '';

        setTimeout(() => {
          this.$router.push('/login');
        }, 2000);
      } catch (error) {
        this.errorMessage = error.response.data.error || 'Failed to create account.';
        this.successMessage = '';
      }
    }
  }
};
</script>

<style scoped>
.register-container {
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  background-color: #192734;
  border-radius: 16px;
  color: #d9d9d9;
}

h2 {
  text-align: center;
  color: #1da1f2;
  margin-bottom: 20px;
}

.register-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.terms-group {
  display: flex;
  align-items: center;
  gap: 8px;
}

.terms-group .terms-link {
  color: #1da1f2;
  cursor: pointer;
  text-decoration: underline;
}

.terms-group .terms-link:hover {
  color: #0c8ddb;
}

label {
  color: #8899a6;
  margin-bottom: 5px;
}

.input-field {
  padding: 12px;
  background-color: #15202b;
  border: 1px solid #38444d;
  border-radius: 10px;
  color: #d9d9d9;
  font-size: 16px;
}

.submit-button {
  padding: 12px 20px;
  background-color: #1da1f2;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0c8ddb;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background: #15202b;
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  width: 90%;
  color: #d9d9d9;
}

.terms-content {
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 20px;
  text-align: left;
}

.close-button {
  background-color: #1da1f2;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  display: block;
  margin: 0 auto;
}

.close-button:hover {
  background-color: #0c8ddb;
}
</style>
