<template>
    <div class="business-partners">
      <!-- Page Header -->
      <header class="header">
        <h1>Promote Your Ski Products</h1>
        <p>
          We offer you the unique opportunity to showcase your ski products directly within the timeline of our active and engaged slalom skiers community.
        </p>
      </header>
  
      <!-- Promotion Example Section -->
      <section class="promotion-example">
        <div class="example-container">
          <img
            :src="require('@/assets/ad.png')"
            alt="Example of Ski Product Promotion"
            class="example-image"
          />
          <div class="example-text">
            <h2>Example Ad</h2>
            <p class="example-caption">
                Showcase your ski products with your logo, text, image/video, accompanied by a "Learn More" button linking to your website or landing page. Ads are prominently displayed to all users every five posts in the home timeline.
                <br><br>
                Here's an example of how a D3 SKIS ad might appear.
                </p>
          </div>
        </div>
      </section>
    </div>

    <div class="business-partners">
      <!-- Contact Us Section -->
      <section class="contact-us">
        <h2>Contact Us to Get Started</h2>
        <p>
          Ready to promote your ski products to an audience that shares your passion? 
          Reach out to us, and we’ll help you get started!
        </p>
        <a href="/contact">
        <button class="contact-button">Contact Us</button>
        </a>
      </section>
    </div>

   <FooterPublic />
  </template>
  
  <script>
  import FooterPublic from "@/components/FooterPublic.vue";
  export default {
    name: "BusinessPartners",
    components: {
        FooterPublic,
    },
    methods: {
      navigateToContact() {
        this.$router.push("/contact");
      },
    },
  };
  </script>
  
  <style scoped>
  .business-partners {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    color: #d9d9d9;
    background-color: #192734;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 12px;
  }
  
  .header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .header h1 {
    font-size: 2.5em;
    color: #ffffff;
    margin-bottom: 10px;
  }
  
  .header p {
    font-size: 1.2em;
    color: #ffffff;
  }
  
  .promotion-example {
    margin-bottom: 40px;
  }
  
  .promotion-example h2 {
    font-size: 2em;
    color: #ffffff;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .example-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 16px;
  }
  
  .example-image {
    width: 100%;
    max-width: 500px;
    border-radius: 16px;
    margin-bottom: 20px;
  }
  
  .example-text {
    max-width: 400px;
  }


  
  .example-caption {
    font-size: 1em;
    color: #ffffff;
    text-align: left;
  }
  
  @media screen and (min-width: 768px) {
    .example-container {
      flex-direction: row;
      align-items: flex-start;
      gap: 20px;
    }
  
    .example-text, .example-text h2 {
      text-align: left;
    }
  
    .example-image {
      max-width: 500px;
      margin-bottom: 0;
    }
  }
  
  .contact-us {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .contact-us h2 {
    font-size: 2em;
    color: #ffffff;
    margin-bottom: 10px;
  }
  
  .contact-us p {
    font-size: 1.2em;
    color: #ffffff;
    margin-bottom: 20px;
  }
  
  .contact-button {
    padding: 10px 20px;
    background-color: #1da1f2;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-button:hover {
    background-color: #0c8ddb;
  }
  
 
  </style>
  