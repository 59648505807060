import { createRouter, createWebHistory } from 'vue-router';
import PublicHome from '@/components/PublicHome.vue';
import HomeTimeline from '@/assets/pages/HomeTimeline.vue';
import LoginForm from '@/components/LoginForm.vue';
import PostCreate from '@/components/PostCreate.vue';
import RegisterForm from '@/components/RegisterForm.vue';
import EditProfile from '@/components/EditProfile.vue';
import FollowersFollowing from '@/components/FollowersFollowing.vue';
import UserFollowersFollowing from '@/components/UserFollowersFollowing.vue';
import AllSkiers from '@/components/AllSkiers.vue';
import PrivacyPolicy from '@/components/PrivacyPolicy.vue';
import TermsOfService from '@/components/TermsOfService.vue';
import ContactForm from '@/components/ContactForm.vue';
import UserProfile from '@/assets/pages/UserProfile.vue';
import RequestProTip from '@/components/RequestProTip.vue';
import BusinessPartners from '@/components/BusinessPartners.vue';
import PaidTipsExplainer from '@/components/PaidTipsExplainer.vue';


const routes = [
  {
    path: '/',
    name: 'PublicHome',
    component: PublicHome
  },
  {
    path: '/timeline',
    name: 'HomeTimeline',
    component: HomeTimeline
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginForm,
  },
  {
    path: '/post/create',
    name: 'PostCreate',
    component: PostCreate,
  },
  {
    path: '/create-account',
    name: 'Register',
    component: RegisterForm,
  },
  {
    path: '/edit-profile',
    name: 'EditProfile',
    component: EditProfile,
  },
  {
    path: "/:username",
    component: UserProfile,
    name: "UserProfile",
    props: true,
  },
  {
    path: '/my/followers-following',
    name: 'MyFollowersFollowing',
    component: FollowersFollowing,
    meta: { requiresAuth: true }
  },
  {
    path: '/:username/followers-following',
    name: 'UserFollowersFollowing',
    component: UserFollowersFollowing,
    props: true
  },
  {
    path: '/all-skiers',
    name: 'AllSkiers',
    component: AllSkiers,
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '/terms',
    name: 'TermsOfService',
    component: TermsOfService,
  },
  {
   path:  '/contact',
   name: 'ContactForm',
   component: ContactForm,
  },
  {
    path: '/request-pro-tip',
    name: 'RequestProTip',
    component: RequestProTip,
  },
  {
    path: '/business',
    name: 'Business',
    component: BusinessPartners,
  },
  {
    path: '/paid-tips',
    name: 'PaidTips',
    component: PaidTipsExplainer,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
