<template>
  <div id="app">
    <!-- Include the Navbar component -->
    <Navbar 
      :logoSrc="logo" 
      brandName="6buoys" 
      :links="navLinks" 
    />
    <!-- This will render the current route's component -->
    <router-view></router-view>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'; // Import the Navbar component

export default {
  name: 'App',
  components: {
    Navbar, // Register the Navbar component
  },
  data() {
    return {
      logo: require('./assets/logo.png'), // Adjust the path to your logo
      navLinks: [
        { text: 'Home', href: '/' },
        { text: 'Login', href: '/login' },
        // Add more links as needed
      ],
    };
  },
  created() {
    console.log('App component created');
  },
};
</script>

<style>
#app {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
  line-height: 1.5;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
  line-height: 1.5;
  margin: 0;
  background-color: #15202b; /* Dark background for dark mode */
  color: #ffffff; /* Light text color */
}

router-view {
  padding-top: 60px; /* Space for the navbar */
}
</style>
