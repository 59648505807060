<template>
    <div class="terms-container">
      <h2>Terms of Service</h2>
      <div class="terms-content">
        <p><strong>Effective Date:</strong> September 2024</p>
  
        <h3>1. Acceptance of Terms</h3>
        <p>
          By using 6buoys, you agree to these Terms of Service. You are
          responsible for ensuring that you understand and comply with these
          terms.
        </p>
  
        <h3>2. Community Guidelines</h3>
        <ul>
          <li><strong>Be Respectful:</strong> Treat other users with respect and courtesy. Feedback should be constructive and focused on helping others improve.</li>
          <li><strong>Prohibited Content:</strong> Do not post videos, comments, or other content that is offensive, abusive, violent, obscene, discriminatory, or otherwise objectionable. Content that promotes illegal activities is strictly prohibited.</li>
          <li><strong>Legal Compliance:</strong> All content must comply with applicable laws and regulations. Do not post content that infringes on the rights of others, including intellectual property rights.</li>
        </ul>
  
        <h3>3. User Responsibilities</h3>
        <ul>
          <li><strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account information. Do not share your account details with others.</li>
          <li><strong>Content Ownership:</strong> You retain ownership of the content you post, but you grant 6buoys a license to display, share, and distribute your content within the app.</li>
          <li><strong>Content Moderation:</strong> We reserve the right to remove any content that violates our guidelines or terms without notice.</li>
        </ul>
  
        <h3>4. Violations and Enforcement</h3>
        <p>
          <strong>Content Removal:</strong> If your content violates our terms, it
          will be removed.
        </p>
        <p>
          <strong>Account Suspension:</strong> Repeated or severe violations may
          result in temporary or permanent suspension of your account.
        </p>
  
        <h3>5. Reporting Violations</h3>
        <p>
          If you encounter any content that violates these terms, please report it
          using the in-app reporting feature.
        </p>
  
        <h3>6. Changes to the Terms</h3>
        <p>
          We may update these Terms of Service from time to time. Continued use of
          the app after any changes indicates your acceptance of the new terms.
        </p>
  
        <h3>7. Privacy Policy</h3>
        <p>
          Please review our Privacy Policy to understand how we collect, use, and
          protect your data.
        </p>
  
        <h3>8. Contact Us</h3>
        <p>
          If you have questions or concerns about these terms, please contact us
          at 6buoysskiing@gmail.com.
        </p>
      </div>
    </div>
  </template>
  
  <style scoped>
  .terms-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    background-color: #192734;
    border-radius: 16px;
    color: #d9d9d9;
    text-align: left;
  }
  
  h2 {
    text-align: center;
    color: #1da1f2;
    margin-bottom: 20px;
  }
  
  .terms-content {
    line-height: 1.6;
  }
  
  h3 {
    color: #1da1f2;
    margin-top: 20px;
  }
  
  ul {
    padding-left: 20px;
  }
  
  li {
    margin-bottom: 10px;
  }
  
  p {
    margin-bottom: 10px;
  }
  </style>
  