<template>
    <div class="follows-container">
      <h1 class="title">Followers & Following</h1>
  
      <div class="toggle-buttons">
        <button :class="{ active: viewType === 'followers' }" @click="viewType = 'followers'">Followers</button>
        <button :class="{ active: viewType === 'following' }" @click="viewType = 'following'">Following</button>
      </div>
  
      <LoadingSpinner v-if="isLoading" />
  
      <div v-if="!isLoading && displayedList.length" class="follows-list">
        <div v-for="profile in displayedList" :key="profile.id" class="follow-card">
          <a :href="`/${profile.username}`">
            <img :src="profile.avatar || defaultAvatar" alt="Profile Picture" class="follow-avatar" />
          </a>
          <div class="follow-info">
            <h2 class="follow-username">
              {{ profile.username }}
              <img
                v-if="profile.verified"
                src="@/assets/verified.png"
                alt="Verified"
                class="verified-badge"
                title="Verified User"
              />
            </h2>
            <p class="follow-bio">{{ formatBio(profile.bio || '') }}</p>
          </div>
          <!-- Follow/Unfollow Button on the right side -->
          <button
            v-if="!isLoadingFollowing"
            class="follow-button"
            @click="toggleFollow(profile)"
            :class="{ 'unfollow': isFollowing(profile.id) }"
          >
            {{ isFollowing(profile.id) ? 'Unfollow' : 'Follow' }}
          </button>
        </div>
      </div>
  
      <div v-else-if="!isLoading">
        <p>No {{ viewType }} found.</p>
      </div>
    </div>
  </template>
  
  
  <script>
  import axios from 'axios';
  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  
  export default {
    components: {
      LoadingSpinner,
    },
    data() {
      return {
        userID: null,
        username: null,
        followers: [],
        following: [],
        defaultAvatar: '/path/to/default/avatar.png', // Path to default avatar
        isLoading: true,
        isLoadingFollowing: true,
        viewType: 'followers', // Toggle between followers and following
        hoveredSkierId: null,
      };
    },
    computed: {
      // Dynamically show either followers or following based on the selected view
      displayedList() {
        return this.viewType === 'followers' ? this.followers : this.following;
      },
    },
    async created() {
      const queryViewType = this.$route.query.viewType || 'followers';
      this.viewType = queryViewType;
      this.username = this.$route.params.username;
      await this.fetchUserIDByUsername();
      await this.fetchFollowers();
      await this.fetchFollowing();
    },
    methods: {
      async fetchUserIDByUsername() {
        try {
          const response = await axios.get(`https://api.6buoys.com/api/p/${this.username}/`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
          });
          // The user ID will be in the user field of the profile
          this.userID = response.data.id;
        } catch (error) {
          console.error('Error fetching user ID by username:', error);
        }
      },
      async fetchFollowers() {
        try {
            const response = await axios.get(`https://api.6buoys.com/api/user-follows/${this.userID}/`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            });

            const follows = response.data;

            // Modified to handle failed profile fetches
            this.followers = (await Promise.all(
                follows.map(async (follow) => {
                    try {
                        const profileResponse = await axios.get(`https://api.6buoys.com/api/profile/${follow.sender}/`, {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                            },
                        });
                        return profileResponse.data;
                    } catch (error) {
                        console.log(`Skipping non-existent profile for user ${follow.sender}`);
                        return null;
                    }
                })
            )).filter(profile => profile !== null); // Remove null entries

            console.log("Full Followers Data with Profiles:", this.followers);
        } catch (error) {
            console.error('Error fetching followers:', error);
        } finally {
            this.isLoading = false;
        }
      },
      async fetchFollowing() {
        try {
            const response = await axios.get(`https://api.6buoys.com/api/user-following/${this.userID}/`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            });

            const followings = response.data;

            // Modified to handle failed profile fetches
            this.following = (await Promise.all(
                followings.map(async (following) => {
                    try {
                        const profileResponse = await axios.get(`https://api.6buoys.com/api/profile/${following.receiver}/`, {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                            },
                        });
                        return profileResponse.data;
                    } catch (error) {
                        console.log(`Skipping non-existent profile for user ${following.receiver}`);
                        return null;
                    }
                })
            )).filter(profile => profile !== null); // Remove null entries

            console.log("Full Following Data with Profiles:", this.following);
        } catch (error) {
            console.error('Error fetching following:', error);
        } finally {
            this.isLoading = false;
            this.isLoadingFollowing = false;
        }
      },
      isFollowing(profileID) {
            // Check if the profile is in the following list
            return this.following.some((f) => f.id === profileID);
        },
      formatBio(bio) {
        return bio.length > 140 ? bio.substring(0, 140) + '...' : bio;
      },
      async toggleFollow(profile) {
        try {
        if (this.isFollowing(profile.id)) {
            // Unfollow action
            await axios.delete(`https://api.6buoys.com/api/follow-delete/${this.userID}/${profile.id}/`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
            });
            // Remove profile from following list
            this.following = this.following.filter((f) => f.id !== profile.id);
        } else {
            await axios.post(`https://api.6buoys.com/api/follow-create/${this.userID}/${profile.id}/`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
                }
            );
            // Add profile to following list
            this.following.push(profile);
        }
        } catch (error) {
        console.error('Error toggling follow:', error);
        }        
    },
    },
  };
  </script>
  
  <style scoped>
 .follows-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #192734;
  border-radius: 16px;
  color: #d9d9d9;
}

.title {
  text-align: center;
  color: #1da1f2;
  margin-bottom: 20px;
}

.toggle-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.toggle-buttons button {
  background-color: transparent;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  border-radius: 8px;
  cursor: pointer;
}

.toggle-buttons button:hover {
    background-color: #1da1f2;
}

.toggle-buttons button.active {
  background-color: #15202b;
  border: 2px solid #1da1f2;
}

.follows-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.follow-card {
  display: flex;
  position: relative;
  background-color: #15202b;
  align-items: center;
  padding: 15px;
  border-radius: 12px;
  width: 100%;
  max-width: 350px;
  align-items: flex-start;
  gap: 15px;
  overflow: hidden;
  min-height: 100px; /* Ensures space for two lines of bio */
}

.follow-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #1da1f2;
  flex-shrink: 0;
}

.follow-info {
  flex-grow: 1;
  min-width: 0;
  padding-right: 70px; /* Add right padding to avoid overlap with the button */
}

.follow-username {
  margin: 0;
  font-size: 1.2em;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 5px;
}

.verified-badge {
  width: 18px;
  height: 18px;
}

.follow-bio {
  margin: 5px 0 0;
  color: #8899a6;
  font-size: 0.9em;
  white-space: normal; /* Allow bio to wrap */
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 40px; /* Limit the height to two lines of text */
  text-align: left; /* Align bio text to the left */
}

.follow-button {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #1da1f2;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
}

.follow-button.unfollow {
  background-color: transparent;
  border: solid white;
}

.follow-button.unfollow:hover {
  background-color: #ff4757;
  border: solid #ff4757;
}

  </style>
  