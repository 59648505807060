<template>
       <!-- Footer Section -->
   <footer class="footer">
      <div class="footer-container">
        <!-- Business Category -->
        <div class="footer-category">
          <h3>Business</h3>
          <ul>
            <li><a href="/business">Promote Ski Products</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </div>

        <!-- Skiers Category -->
        <div class="footer-category">
          <h3>Skiers</h3>
          <ul>
            <li><router-link to="/all-skiers">All Skiers</router-link></li>
            <li v-if="isWeb"><a href="/paid-tips">Pay for Tips from Pros</a></li>
          </ul>
        </div>

        <!-- Pro Skiers Category -->
        <div class="footer-category">
          <h3>Pro Skiers</h3>
          <ul>
            <li><a href="paid-tips"> Request Pro Verification</a></li>
            <li v-if="isWeb"><a href="paid-tips">Get Paid for Tips</a></li>
          </ul>
        </div>

        <div class="footer-category">
          <h3>App</h3>
          <ul>
            <li><a href="https://apps.apple.com/gb/app/6buoys/id6686394530?uo=2" target="_blank"> IOS App</a></li>
          </ul>
        </div>

        <!-- 6buoys Category -->
        <div class="footer-category">
          <h3>6buoys</h3>
          <ul>
            <li><a href="/contact">Contact</a></li>
            <li><router-link to="/login">login</router-link></li>
          </ul>
        </div>
      </div>

      <div class="quote">
        <p>We Coach You to the Next Buoy</p>
      </div>
    </footer>
</template>


<script>
import { Capacitor } from '@capacitor/core';

export default {
  name: "FooterPublic",
  data() {
    return {
      isWeb: null
    };
  },
  created() {
    const platform = Capacitor.getPlatform();
    this.isWeb = platform === 'web';
  },
}
</script>

<style>
.footer {
  background-color: #192734;
  padding: 40px 20px;
  color: white;
  text-align: left;
  border-top: 1px solid #38444d;
}

.footer-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 5%;
}

@media screen and (max-width: 600px) {
  .footer-container {
    padding-left: 0px 0px 56px 0px;
  }
}

.footer-category {
  flex: 1;
  min-width: 150px;
  max-width: 200px;
  margin-bottom: 20px;
}

.footer-category h3 {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
}

.footer-category ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-category li {
  margin: 8px 0;
}

.footer-category a {
  color: white;
  text-decoration: none;
  font-size: 0.95em;
  transition: color 0.3s ease;
}

.footer-category a:hover {
  color: #1da1f2;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
  }

}

.quote {
  text-align: center;
  color: white;
  font-size: 1.2em;
  font-weight: bold;
}

.quote p {
  padding: 0px !important;
}
</style>