<template>
  <div class="profile-container">
    <div class="profile-header">
      <img :src="avatar || defaultAvatar" alt="Profile Picture" class="profile-avatar" />
      <div class="profile-info">
        <div class="name-container">
          <h2>
            {{ profile.first_name }} {{ profile.last_name }}
            <img
              v-if="profile.verified"
              src="@/assets/verified.png"
              alt="Verified"
              class="verified-badge"
              title="Verified Skier"
            />
          </h2>
        </div>
        <span class="username">@{{ profile.username }}</span>
        <p class="bio">{{ profile.bio }}</p>
        <p v-if="profile.lake" class="info-line">
          <i class="fas fa-water"></i> {{ profile.lake }}
        </p>
        <p v-if="profile.ski" class="info-line">
          <i class="fas fa-skiing"></i> {{ profile.ski }}
        </p>
        <p v-if="profile.PB" class="info-line">
          <i class="fas fa-trophy"></i> {{ profile.PB }}
        </p>
      </div>
      <router-link
        v-if="isAuthenticated && userId === profile.id"
        :to="{ name: 'EditProfile' }"
        class="edit-profile-button"
      >
        Edit
      </router-link>
    </div>

    <div class="profile-stats">
      <div class="stat">
        <span>{{ profile.posts_count }}</span>
        <p>Posts</p>
      </div>
      <div class="stat">
        <router-link
          :to="{ 
            path: isAuthenticated && userId === profile.id 
              ? '/my/followers-following' 
              : `/${profile.username}/followers-following`,
            query: { viewType: 'followers' } 
          }"
          class="stat-link"
        >
          <span>{{ profile.followers_count }}</span>
          <p>Followers</p>
        </router-link>
      </div>
      <div class="stat">
        <router-link
          :to="{ 
            path: isAuthenticated && userId === profile.id 
              ? '/my/followers-following' 
              : `/${profile.username}/followers-following`,
            query: { viewType: 'following' } 
          }"
          class="stat-link"
        >
          <span>{{ profile.following_count }}</span>
          <p>Following</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileContainer',
  props: {
    profile: {
      type: Object,
      required: true
    },
    isAuthenticated: {
      type: Boolean,
      default: false
    },
    userId: {
      type: Number,
      default: null
    },
    avatar: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      defaultAvatar: "https://6buoys-bucket.s3.amazonaws.com/avatar.png"
    }
  },
  computed: {
    hasSkiingStats() {
      return this.profile.ski || this.profile.lake || this.profile.PB;
    }
  }
}
</script>

<style scoped>
.profile-container {
  max-width: 560px;
  margin: 0px auto;
  padding: 20px;
  background-color: #192734;
  border-radius: 16px;
  color: #d9d9d9;
  text-align: left;
}

.profile-header {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 20px;
  position: relative;
}

.profile-avatar {
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-info {
  flex: 1;
}

.profile-info h2 {
  margin: 0;
  color: #1da1f2;
}

.profile-info p {
  margin: 5px 0;
}

.bio {
  color: #8899a6;
}

.profile-stats {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
}

.stat span {
  color: #1da1f2;
  font-size: 1.8em;
}

.stat p {
  color: #8899a6;
  margin-top: 2px;
}

.edit-profile-button {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #1da1f2;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 24px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-decoration: none;
}

.edit-profile-button:hover {
  background-color: #1a91da;
  transform: scale(1.05);
}

.info-line {
  font-size: 0.85em;
  color: #8899a6;
  display: flex;
  align-items: center;
  gap: 6px;
}

.info-line i {
  color: #1da1f2;
  font-size: 0.9em;
}

.name-container {
  display: block;
}

.name-container h2 {
  margin: 0;
  color: #d9d9d9;
  display: flex;
  align-items: center;
  gap: 5px;
}

.username {
  color: #1da1f2;
  font-size: 0.9em;
}

.verified-badge {
  width: 24px;
  height: 24px;
}
</style> 