<template>
    <div>
      <!-- Back Link -->
      <div class="back-link-section">
        <router-link to="/all-skiers" class="back-link">
          <span class="arrow">&#8592;</span>
          <span class="back-link-text">View All Skiers</span>
        </router-link>
      </div>
  
      <!-- Profile Section -->
      <ProfileContainer 
        v-if="profile.username"
        :profile="profile"
        :isAuthenticated="isAuthenticated"
        :userId="user.id"
        :avatar="avatarPreview || profile.avatar"
      />
  
      <!-- Toggle Buttons for Posts vs. Comments vs. Paid Tips -->
      <div class="toggle-buttons">
        <button
          :class="{ active: viewType === 'posts' }"
          @click="viewType = 'posts'"
        >
          Posts
        </button>
        <button
          :class="{ active: viewType === 'comments' }"
          @click="viewType = 'comments'"
        >
          Coaching Tips
        </button>
        <!--<button
          v-if="isAuthenticated && user.id === profile.id && isWeb === true"
          :class="{ active: viewType === 'paidTipsFromPros' }"
          @click="viewType = 'paidTipsFromPros'"
        >
          Paid Tips
        </button>
         <button
          v-if="isWeb && isAuthenticated && user.verified"
          :class="{ active: viewType === 'tipRequests' }"
          @click="viewType = 'tipRequests'"
        >
          Tip Requests
        </button> -->
      </div>
  
      <!-- POSTS SECTION -->
      <div v-if="viewType === 'posts'" class="timeline">
        <LoadingSpinner v-if="loadingPosts" />
        <template v-else>
          <PostCard 
            v-for="post in posts" 
            :key="post.id" 
            :post="post"
            :ref="(el) => { if (el) postCards[post.id] = el }"
            @tips-count-updated="(count) => updateTipsCount(post.id, count)"
            @like-updated="(likeData) => updateLike(post.id, likeData)"
          />
        </template>
      </div>
  
      <!-- COMMENTS SECTION (Rating + Replies) -->
      <div v-else-if="viewType === 'comments'" class="comments-section">
        <LoadingSpinner v-if="loadingComments" />
        <WaterskiTips 
          v-else
          :post-id="profile.id"
          :initial-tips="comments"
          @update:tips-count="updateCommentsCount"
        />
      </div>
  
      <!-- PAID TIPS FROM PROS SECTION -->
    <div v-else-if="viewType === 'paidTipsFromPros'" class="tips-from-pros">
      <LoadingSpinner v-if="loadingPaidTips" />
      <div v-else>
        <!-- If no paid tips, show a quick message -->
        <p v-if="paidTipsFromPros.length === 0">
          No Paid Tips from Pro Skiers yet, request your first Pro Coaching Tip:
          <button class="edit-profile-button" @click="goToRequestProTip">Request Pro Tip</button>
        </p>

        <!-- Otherwise list them -->
        <ul v-else class="paid-tips-list">
        <li v-for="payment in paidTipsFromPros" :key="payment.id" class="payment-item">
            <!-- First Row: Profile picture and status/price -->
            <div class="first-row">
            <div class="pro-info">
                <img
                :src="payment.pro_avatar || defaultAvatar"
                class="pro-avatar"
                alt="Pro Avatar"
                />
                <span class="pro-username">
                {{ payment.pro_username }}
                </span>
            </div>
            <div class="payment-details">
                <div class="payment-status">
                <strong>Status:</strong> {{ payment.status }}
                </div>
                <div class="payment-price">
                <strong>Price:</strong> ${{ payment.amount }}
                </div>
            </div>
            </div>

            <!-- Second Row: Video preview or content -->
            <div class="second-row">
            <div class="post-info" v-if="payment.post_preview">
                <img
                v-if="payment.post_preview.image"
                :src="payment.post_preview.image"
                alt="Post image"
                class="post-thumbnail"
                />
                <video
                v-if="payment.post_preview.video"
                :src="payment.post_preview.video"
                muted
                autoplay
                loop
                playsinline
                class="post-thumbnail"
                ></video>
                <p class="paid-post-content">{{ payment.post_preview.content }}</p>
            </div>
            </div>
        </li>
        </ul>
      </div>
    </div>
  
    <!-- Tip Requests Section -->
    <div v-else-if="viewType === 'tipRequests'" class="tips-from-pros">
      <LoadingSpinner v-if="loadingTipRequests" />
      <div v-else>
        <p v-if="tipRequests.length === 0">
          No tip requests yet.
        </p>
        <ul v-else class="paid-tips-list">
          <li v-for="request in tipRequests" :key="request.id" class="payment-item">
            <!-- User Info and Payment Details -->
            <div class="first-row">
              <div class="pro-info">
                <img
                  :src="request.user_avatar || defaultAvatar"
                  class="pro-avatar"
                  alt="User Avatar"
                />
                <span class="pro-username">
                  {{ request.user_username }}
                </span>
              </div>
              <div class="payment-details">
                <div class="payment-status">
                  <strong>Status:</strong> {{ request.status }}
                </div>
                <div class="payment-price">
                  <strong>Price:</strong> ${{ request.amount }}
                </div>
              </div>
            </div>

            <!-- Post Preview with Full-Size Video -->
            <div class="post-content" v-if="request.post_preview">
              <img 
                v-if="request.post_preview.image" 
                :src="request.post_preview.image" 
                alt="Post image" 
                class="post-media"
              />
              <div class="video-container" v-if="request.post_preview.video">
                <video
                  :src="request.post_preview.video"
                  muted
                  autoplay
                  loop
                  playsinline
                  class="post-media"
                ></video>
              </div>
              <p class="post-text">{{ request.post_preview.content }}</p>
            </div>

            <!-- Add Tip Form -->
            <div v-if="request.status === 'pending'" class="add-tip-form">
              <textarea 
                v-model="newTips[request.id]" 
                placeholder="Write your coaching tip here..."
                class="tip-textarea"
              ></textarea>
              <button 
                @click="submitTip(request.id)" 
                class="submit-tip-btn"
                :disabled="!newTips[request.id]"
              >
                Submit Tip
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
    </div>
  </template>
  
  <script>
  import ProfileContainer from '@/components/ProfileContainer.vue';
  import PostCard from '@/components/PostCard.vue';
  import WaterskiTips from '@/components/WaterskiTips.vue';
  import axios from "axios";
  import LoadingSpinner from "@/components/LoadingSpinner.vue";
  import { Capacitor } from '@capacitor/core';


  
  export default {
    components: {
      LoadingSpinner,
      ProfileContainer,
      PostCard,
      WaterskiTips
    },
    props: ["username"],
    data() {
      return {
        profile: {
          username: "",
          first_name: "",
          last_name: "",
          bio: "",
          avatar: "",
          posts_count: 0,
          followers_count: 0,
          following_count: 0,
        },
        posts: [],
        comments: [],              // For user-received comments
        defaultAvatar: "https://6buoys-bucket.s3.amazonaws.com/avatar.png",
        loadingPosts: true,
        loadingComments: false,
  
        /* Authentication & user info */
        user: {},
        isAuthenticated: false,
  
        /* For toggling: 'posts', 'comments', or 'paidTipsFromPros' */
        viewType: "posts",
  
        /* For comment voting & replies */
        userVotes: {},            // commentId => { rate: +1/-1, rateId: someNumber }
        newReply: {},             // commentId => 'reply text'
  
        /* For Paid Tips from Pros */
        paidTipsFromPros: [],
        loadingPaidTips: false,
        paidTipsFromProsLoaded: false,
        isWeb: false,
        tipRequests: [],
        loadingTipRequests: false,
        tipRequestsLoaded: false,
        newTips: {},
        postCards: {},
      };
    },
    async created() {
      await this.checkAuthentication();
      await this.fetchProfile();
      // Only fetch posts after we're sure about authentication
      if (this.isAuthenticated && this.user.id) {
        await this.fetchPosts();
      } else {
        await this.fetchPosts();  // Fallback for non-authenticated view
      }
  
      const queryViewType = this.$route.query.viewType;
      this.viewType = queryViewType || "posts"; // Default to "posts" if not specified
      
      if (this.viewType === "paidTipsFromPros" && this.isAuthenticated) {
        await this.fetchPaidTipsFromPros();
      }
      
      const platform = Capacitor.getPlatform();
      this.isWeb = platform === 'web';
    },
    watch: {
      // Whenever 'viewType' changes, load the relevant data if not already loaded
      viewType(newVal) {
        if (newVal === "comments" && this.comments.length === 0) {
          this.fetchComments();
        }
        if (newVal === "paidTipsFromPros" && !this.paidTipsFromProsLoaded) {
          this.fetchPaidTipsFromPros();
        }
        if (newVal === "tipRequests" && !this.tipRequestsLoaded) {
          this.fetchTipRequests();
        }
      },
      '$route.query.viewType': function (newVal) {
        this.viewType = newVal || "posts";
        if (this.viewType === "paidTipsFromPros") {
          this.fetchPaidTipsFromPros();
        }
        if (this.viewType === "tipRequests") {
          this.fetchTipRequests();
        }
      },
      '$route.params.username': {
        immediate: true,
        handler: async function(newUsername) {
          if (newUsername) {
            this.loadingPosts = true;
            await this.checkAuthentication();
            await this.fetchProfile();
            await this.fetchPosts();
            
            // Reset view type to posts when profile changes
            this.viewType = "posts";
            
            // Reset other data as needed
            this.comments = [];
            this.paidTipsFromPros = [];
            this.paidTipsFromProsLoaded = false;
            this.tipRequests = [];
            this.tipRequestsLoaded = false;
          }
        }
      },
    },
    methods: {
      /* ---------------- AUTH ----------------- */
      async checkAuthentication() {
        const token = localStorage.getItem("access_token");
        if (token) {
          try {
            const response = await axios.get("https://api.6buoys.com/api/profile/", {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            this.user = response.data;
            this.isAuthenticated = true;
          } catch (error) {
            console.error("Error fetching user data:", error);
            this.isAuthenticated = false;
          }
        } else {
          this.isAuthenticated = false;
        }
      },
  
      /* ---------------- PROFILE / POSTS ---------------- */
      async fetchProfile() {
        try {
          const response = await axios.get(`https://api.6buoys.com/api/p/${this.username}/`);
          this.profile = response.data;
        } catch (error) {
          console.error("Error fetching profile:", error);
        }
      },
      async fetchPosts() {
        try {
          const token = localStorage.getItem("access_token");
          if (!token && !this.isAuthenticated) {
            console.log("Fetching posts without authentication");
          }
          
          const response = await axios.get(`https://api.6buoys.com/api/posts/${this.profile.id}/`, {
            headers: token ? {
              Authorization: `Bearer ${token}`
            } : {},
            params: {
              user_id: this.user.id || null  // Explicitly pass the user ID
            }
          });
          
          const newPosts = response.data.map(post => ({
            ...post,
            showTips: false,
            tips: [],
            tips_count: post.tips_count || 0,
            likes_count: post.likes_count || 0,
            liked_by_user: post.liked_by_user || false,
            like_id: post.like_id || null,
            author: post.author || {
              id: null,
              username: '',
              avatar: 'default-avatar.png',
              first_name: 'Unknown',
              last_name: ''
            }
          }));

          this.posts = newPosts;

          // Preload tips for all posts
          this.$nextTick(() => {
            this.posts.forEach(post => {
              const postCard = this.postCards[post.id];
              if (postCard && postCard.preloadTips) {
                postCard.preloadTips();
              }
            });
          });
        } catch (error) {
          console.error("Error fetching posts:", error);
        } finally {
          this.loadingPosts = false;
        }
      },
  
      /* ---------------- COMMENTS (Coaching Tips) ---------------- */
      async fetchComments() {
        this.loadingComments = true;
        try {
          // Fetch comments
          const response = await axios.get(`https://api.6buoys.com/api/user-received-comments/${this.profile.id}/`);
          
          // Fetch user votes if authenticated
          let userVotes = {};
          if (this.isAuthenticated) {
            const votesResponse = await axios.get('https://api.6buoys.com/api/user-comment-rates/', {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
              },
            });
            userVotes = votesResponse.data.reduce((acc, vote) => {
              acc[vote.comment] = { rate: vote.rate, rateId: vote.id };
              return acc;
            }, {});
          }

          // Merge comments with user votes
          this.comments = response.data.map(comment => ({
            ...comment,
            userVote: userVotes[comment.id]?.rate || 0,
            rateId: userVotes[comment.id]?.rateId || null
          }));
        } catch (error) {
          console.error("Error fetching comments:", error);
        } finally {
          this.loadingComments = false;
        }
      },
  
      /* ---------------- PAID TIPS FROM PROS ---------------- */
      async fetchPaidTipsFromPros() {
        this.loadingPaidTips = true;
        try {
          const token = localStorage.getItem("access_token");
          if (!token) {
            console.error("No authentication token found");
            return;
          }

          const response = await axios.get(
            `https://api.6buoys.com/payments/user-payments/${this.profile.user_id}/`,
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          );
          this.paidTipsFromPros = response.data;
          this.paidTipsFromProsLoaded = true;
        } catch (error) {
          console.error("Error fetching paid tips:", error);
        } finally {
          this.loadingPaidTips = false;
        }
      },
      goToRequestProTip() {
        // Navigate to your pro-tip request page
        this.$router.push("/request-pro-tip");
      },
  
      /* ---------------- UTILITIES ---------------- */
      formatDate(date) {
        if (!date) return "";
        const options = { year: "numeric", month: "short", day: "numeric" };
        return new Date(date).toLocaleDateString(undefined, options);
      },
      async fetchTipRequests() {
        this.loadingTipRequests = true;
        try {
          const response = await axios.get(
            `https://api.6buoys.com/payments/pro-payments/${this.profile.id}/`
          );
          this.tipRequests = response.data;
          this.tipRequestsLoaded = true;
        } catch (error) {
          console.error("Error fetching tip requests:", error);
        } finally {
          this.loadingTipRequests = false;
        }
      },
      async submitTip(requestId) {
        if (!this.isAuthenticated) {
          alert("Please log in to submit a tip.");
          return;
        }

        const tipText = this.newTips[requestId]?.trim();
        if (!tipText) {
          alert("Please enter a valid tip.");
          return;
        }

        try {
          const token = localStorage.getItem("access_token");
          
          // First, submit the tip
          const tipResponse = await axios.post(
            `https://api.6buoys.com/api/comment-create/`,
            {
              post: this.tipRequests.find(r => r.id === requestId)?.post_preview?.id,
              body: tipText,
              payment: requestId
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          // If tip is submitted successfully, capture the payment
          if (tipResponse.status === 201 || tipResponse.status === 200) {
            // Get the payment intent ID from the request
            const request = this.tipRequests.find(r => r.id === requestId);
            const paymentIntentId = request.stripe_payment_intent_id;

            // Capture the payment
            const captureResponse = await axios.post(
              'https://api.6buoys.com/payments/capture-intent/',
              {
                payment_id: paymentIntentId
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            if (captureResponse.data.success) {
              // Clear the input and update UI
              this.$set(this.newTips, requestId, '');
              
              // Reload tip requests to get updated data
              await this.fetchTipRequests();

              // Show success message
              alert("Tip submitted and payment captured successfully!");
            } else {
              throw new Error("Failed to capture payment");
            }
          }
        } catch (error) {
          // More detailed error handling
          const errorMessage = error.response?.data?.error || error.response?.data?.message || "Failed to submit tip. Please try again.";
          console.error("Error submitting tip:", error);
          alert(errorMessage);
        }
      },
      updateTipsCount(postId, count) {
        const post = this.posts.find(p => p.id === postId);
        if (post) {
          post.tips_count = count;
        }
      },
      updateLike(postId, likeData) {
        const post = this.posts.find(p => p.id === postId);
        if (post) {
          post.liked_by_user = likeData.liked_by_user;
          post.likes_count = likeData.likes_count;
          post.like_id = likeData.like_id;
        }
      },
      updateCommentsCount() {
        // Optionally update the comments count in the profile if needed
        this.fetchProfile();
      },
    },
  };
  </script>
  
  <style scoped>
  /* Profile Section Styling */
  .back-link-section {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .back-link {
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .arrow {
    font-size: 1.5em;
    margin-right: 8px;
    vertical-align: middle;
  }
  
  .profile-container {
    max-width: 560px;
    margin: 0px auto;
    padding: 20px;
    background-color: #192734;
    border-radius: 16px;
    color: #d9d9d9;
    text-align: left;
  }
  
  .profile-header {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .profile-avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .profile-info h2 {
    margin: 0;
    color: #1da1f2;
  }
  
  .profile-info p {
    margin: 5px 0;
  }
  
  .bio {
    color: #8899a6;
  }
  
  .profile-stats {
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .stat span {
    color: #1da1f2;
    font-size: 1.8em;
  }
  
  .stat p {
    color: #8899a6;
    margin-top: 2px;
  }
  
  .edit-profile-button {
    background-color: #1da1f2;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 24px;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-decoration: none;
  }
  
  .edit-profile-button:hover {
    background-color: #1a91da;
    transform: scale(1.05);
  }
  
  /* Toggle Buttons for Posts vs. Comments vs. Paid Tips */
  .toggle-buttons {
    display: flex;
    justify-content: center;
    margin: 20px auto;
    gap: 10px;
  }
  
  .toggle-buttons button {
    background-color: transparent;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .toggle-buttons button:hover {
    background-color: #1da1f2;
  }
  
  .toggle-buttons button.active {
    background-color: #15202b;
    border: 2px solid #1da1f2;
  }
  
  /* POSTS SECTION */
  .timeline {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #15202b;
  }
  
  @media screen and (max-width: 600px) {
    .timeline {
      padding: 20px 0px 56px 0px;
    }
  }

   /* TIPS SECTION */
   .comments-section {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #15202b;
  }
  
  @media screen and (max-width: 600px) {
    .comments-section {
      padding: 20px 4px 56px 4px;
    }
  }
  
  
 /* Paid Tips Section Updates */
 .paid-tips-list {
  max-width: 600px;
  margin: 0 auto; 
  padding-inline-start: 0px;/* Center cards horizontally */
}

.payment-item {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: #192734;
  margin-bottom: 10px;
  padding: 12px;
  max-width: 600px;
  border-radius: 12px;
}

/* First row styling: Profile picture on left, status and price on right */
.payment-item .first-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pro-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pro-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.payment-details {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  text-align: right;
}

.payment-status,
.payment-price {
  font-weight: bold;
  color: #1da1f2;
}

/* Second row styling: Video or content */
.payment-item .second-row {
  display: flex;
  align-items: center;
  gap: 12px;
}

.post-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.paid-post-content {
    margin-left: 10px;
    max-width: 300px;
}

.post-thumbnail {
  width: 100px;
  height: 100px;
  max-height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.post-content {
  color: #d9d9d9;
  text-align: center;
  margin-top: 8px;
}

.post-media {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.add-tip-form {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
}

.tip-textarea {
  flex-grow: 1;
  padding: 6px 10px;
  border-radius: 15px;
  border: 1px solid #38444d;
  background-color: #192734;
  color: #e1e8ed;
}

.submit-tip-btn {
  padding: 6px 12px;
  background-color: #1da1f2;
  color: #ffffff;
  border: none;
  border-radius: 15px;
  cursor: pointer;
}

.submit-tip-btn:hover {
  background-color: #0c8ddb;
}

  </style>
  