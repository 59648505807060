<template>
  <div>

    <div class="profile-container">
      <div class="profile-header">
        <img :src="avatarPreview || profile.avatar || defaultAvatar" alt="Profile Picture" class="profile-avatar" />
        <div class="profile-info">
          <h2>{{ profile.username }}</h2>
          <p>{{ profile.first_name }} {{ profile.last_name }}</p>
          <p class="bio">{{ profile.bio }}</p>
        </div>
      </div>

      <div class="profile-stats">
        <div class="stat">
          <span>{{ profile.posts_count }}</span>
          <p>Posts</p>
        </div>
        <div class="stat">
          <router-link :to="{ path: '/my/followers-following', query: { viewType: 'followers' } }" class="stat-link">
            <span>{{ profile.followers_count }}</span>
            <p>Followers</p>
          </router-link>
        </div>
        <div class="stat">
          <router-link :to="{ path: 'my/followers-following', query: { viewType: 'following' } }" class="stat-link">
            <span>{{ profile.following_count }}</span>
            <p>Following</p>
          </router-link>
        </div>
      </div>


      <form @submit.prevent="updateProfile" class="profile-form">
        <div class="form-group">
          <label for="first_name">First Name</label>
          <input type="text" v-model="profile.first_name" id="first_name" class="input-field" />
        </div>

        <div class="form-group">
          <label for="last_name">Last Name</label>
          <input type="text" v-model="profile.last_name" id="last_name" class="input-field" />
        </div>

        <div class="form-group">
          <label for="bio">Bio</label>
          <textarea v-model="profile.bio" id="bio" class="input-field textarea-field"></textarea>
        </div>

        <div class="form-group">
          <label for="ski">Ski</label>
          <input type="text" v-model="profile.ski" id="ski" class="input-field" />
        </div>

        <div class="form-group">
          <label for="lake">Lake</label>
          <input type="text" v-model="profile.lake" id="lake" class="input-field" />
        </div>

        <div class="form-group">
          <label for="pb">Personal Best</label>
          <input type="text" v-model="profile.PB" id="pb" class="input-field" />
        </div>

        <div class="form-group">
          <label for="avatar">Profile Picture</label>
          <input type="file" @change="onFileChange" id="avatar" class="input-field" />
        </div>
        
        <!-- Delete Account Button -->
        <button type="button" class="delete-button" @click="confirmAccountDeletion">Delete Account</button>
        
        <!-- Update Profile Button -->
        <button type="submit" class="submit-button">Update Profile</button>
      </form>
      
      <!-- First Confirmation Modal -->
      <div v-if="showDeleteConfirmation && !secondConfirmation" class="delete-confirmation-modal">
        <div class="modal-content">
          <h3>Are you sure you want to delete your account?</h3>
          <p>This action cannot be undone. All your data will be permanently deleted.</p>
          <button @click="proceedToFinalConfirmation" class="confirm-delete-button">Yes, delete my account</button>
          <button @click="showDeleteConfirmation = false" class="cancel-button">Cancel</button>
        </div>
      </div>

      <!-- Second Confirmation Modal -->
      <div v-if="secondConfirmation" class="delete-confirmation-modal">
        <div class="modal-content">
          <h3>Are you absolutely sure?</h3>
          <p>This is your final chance to change your mind. Once deleted, your account cannot be recovered.</p>
          <button @click="notifyAccountDeletion" class="confirm-delete-button">Yes, delete my account</button>
          <button @click="cancelFinalConfirmation" class="cancel-button">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  metaInfo: {
    title: 'Edit Profile',
    meta: [
      { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' }
    ]
  },
  data() {
    return {
      profile: {
        first_name: '',
        last_name: '',
        bio: '',
        ski: '',
        lake: '',
        PB: '',
        avatar: '',
        username: '',
        posts_count: 0,
        followers_count: 0,
        following_count: 0,
        user_id: null, 
      },
      defaultAvatar: "https://6buoys-bucket.s3.amazonaws.com/avatar.png",
      avatarPreview: '',
      showDeleteConfirmation: false, 
      secondConfirmation: false,    
    };
  },
  async created() {
    await this.fetchProfile();
  },
  methods: {
    async fetchProfile() {
      try {
        const response = await axios.get('https://api.6buoys.com/api/profile/', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        });

        this.profile = response.data;
        this.user_id = response.data.user_id;
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    },
    async updateProfile() {
      try {
        document.activeElement.blur();
        const formData = new FormData();
        formData.append('first_name', this.profile.first_name);
        formData.append('last_name', this.profile.last_name);
        formData.append('bio', this.profile.bio);
        formData.append('ski', this.profile.ski);
        formData.append('lake', this.profile.lake);
        formData.append('PB', this.profile.PB);
        if (this.profile.avatar instanceof File) {
          formData.append('avatar', this.profile.avatar);
        }
        
        await axios.put(`https://api.6buoys.com/api/profile/${this.user_id}/`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        this.$router.push({ path: `/${this.profile.username}` });
      } catch (error) {
        console.error('Error updating profile:', error);
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];
      if (file) {
        this.profile.avatar = file;
        this.avatarPreview = URL.createObjectURL(file);
      }
    },
    confirmAccountDeletion() {
      this.showDeleteConfirmation = true; // Show the first confirmation modal
    },
    proceedToFinalConfirmation() {
      this.secondConfirmation = true; // Proceed to the second confirmation modal
      this.showDeleteConfirmation = false; // Hide the first confirmation modal
    },
    cancelFinalConfirmation() {
      this.secondConfirmation = false; // Cancel and go back from the second confirmation modal
    },
    notifyAccountDeletion() {
      alert('Your account will be deleted in 14 days.');
      setTimeout(() => {
        this.secondConfirmation = false; // Close the modal after the delay
      }, 2000); // 2000 milliseconds = 2 seconds
    },
  },
};
</script>

<style scoped>
/* Profile Page Styles */

html, body {
  overscroll-behavior: contain; /* Prevent scrolling when input is focused */
}


.arrow {
  font-size: 1.5em;
  margin-right: 8px;
  vertical-align: middle;
}

.profile-container {
  max-width: 500px;
  margin: 20px auto;
  padding: 20px;
  background-color: #192734;
  border-radius: 16px;
  color: #d9d9d9;
  padding-bottom: 20px;
  margin-bottom: 200px;
}

.profile-header {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 20px;
}

.profile-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-info {
  flex: 1;
  text-align: left;
}

.profile-info h2 {
  margin: 0;
  color: #1da1f2;
}

.profile-info p {
  margin: 2px 0;
}

.bio {
  word-wrap: break-word;
  margin-top: 5px;
}

.profile-stats {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.stat {
  text-align: center;
}

.stat span {
  display: block;
  font-size: 1.5em;
  color: #1da1f2;
}

.stat p {
  margin: 0;
  color: #8899a6;
}

.profile-form .form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #d9d9d9;
}

.input-field {
  width: 96%;
  padding: 10px;
  background-color: #15202b;
  border: 1px solid #38444d;
  border-radius: 8px;
  color: #d9d9d9;
  font-size: 16px;
}

.textarea-field {
  resize: vertical;
  min-height: 60px;
  font-size: 16px;
}

.input-field::placeholder {
  color: #8899a6;
}

.submit-button {
  width: 100%;
  padding: 12px;
  background-color: #1da1f2;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #0c8ddb;
}

.delete-button {
  background-color: transparent;
  color: red;
  font-weight: bold;
  padding: 12px;
  width: 100%;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 20px;
}

.delete-button:hover {
  background-color: darkred;
}

/* Modal Styles */
.delete-confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  color: black;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.confirm-delete-button {
  background-color: red;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin: 10px;
  cursor: pointer;
}

.cancel-button {
  background-color: grey;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin: 10px;
  cursor: pointer;
}

.confirm-delete-button:hover {
  background-color: darkred;
}

.cancel-button:hover {
  background-color: darkgrey;
}
</style>
