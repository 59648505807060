<template>
    <div class="privacy-container">
      <h2>Privacy Policy</h2>
      <p>
        Welcome to our app! We are committed to protecting your privacy. This Privacy Policy explains how we handle your information when you use our app.
      </p>
      <p>
        Our app is designed for slalom water skiers to share videos and receive coaching tips from other water skiers. We do not collect any personal data from you.
      </p>
      <h3>Information We Do Not Collect</h3>
      <p>
        We do not collect, store, or share any personal information, such as your name, email address, or any other contact information.
      </p>
      <h3>Third-Party Services</h3>
      <p>
        Our app does not use third-party analytics or advertising services. Any data related to your activity on the app, including the videos you upload, is not collected or shared with any external parties.
      </p>
      <h3>User-Generated Content</h3>
      <p>
        The videos and content you post within the app are visible to other users in the app's community. Please avoid sharing any personal or sensitive information in your posts. Your videos are only used within the app for coaching purposes and are not used for any other purposes.
      </p>
      <h3>Changes to This Policy</h3>
      <p>
        We may update this Privacy Policy from time to time. Any changes will be reflected in the app, and we encourage you to review this page periodically to stay informed about how we are protecting your privacy.
      </p>
      <p>
        By using the app, you agree to the terms of this Privacy Policy.
      </p>
    </div>
  </template>
  
  <script>
  export default {
    metaInfo: {
      title: 'Privacy Policy',
      meta: [
        { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' }
      ]
    }
  };
  </script>
  
  <style scoped>
  .privacy-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background-color: #192734;
    border-radius: 16px;
    color: #d9d9d9;
  }
  
  h2 {
    text-align: center;
    color: #1da1f2;
    margin-bottom: 20px;
  }
  
  h3 {
    color: #1da1f2;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  p {
    margin-bottom: 15px;
    line-height: 1.6;
    color: #8899a6;
  }
  
  @media (max-width: 600px) {
    .privacy-container {
      padding: 15px;
      margin: 20px auto;
    }
  }
  </style>
  