<template>
    <div class="register-container">
      <h2>Contact Us</h2>
      <form @submit.prevent="sendEmail" class="register-form">
        <div class="form-group">
          <label for="email">Email</label>
          <input
            v-model="email"
            type="email"
            id="email"
            class="input-field"
            placeholder="Enter your email"
            required
          />
        </div>
        <div class="form-group">
          <label for="question">Your Question</label>
          <textarea
            v-model="question"
            id="question"
            class="input-field"
            placeholder="Enter your question"
            rows="5"
            required
          ></textarea>
        </div>
        <button type="submit" class="submit-button">Send</button>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
        <p v-if="successMessage" class="success-message">{{ successMessage }}</p>
      </form>
    </div>
    <FooterPublic />
  </template>
  
  <script>
  import * as emailjs from 'emailjs-com';
  import FooterPublic from "@/components/FooterPublic.vue";
  
  export default {
    name: 'ContactForm',
    components: {
      FooterPublic,
    },
    data() {
      return {
        email: '',
        question: '',
        errorMessage: '',
        successMessage: '',
      };
    },
    mounted() {
      // Initialize EmailJS with your public key
      emailjs.init('idIV_DCr3TNm16tnc');
    },
    methods: {
      async sendEmail() {
        if (!this.email || !this.question) {
          this.errorMessage = 'Please fill in all fields.';
          this.successMessage = '';
          return;
        }
  
        const serviceID = 'service_xqbz4g4'; // From the Email Services section
        const templateID = 'template_avofttq'; // From the Email Templates section
  
        // Update these to match your EmailJS template variables
        const templateParams = {
          user_email: this.email, // Match to {{user_email}} in the template
          user_question: this.question, // Match to {{user_question}} in the template
        };
  
        console.log('Template Params:', templateParams); // Debugging log
  
        try {
          await emailjs.send(serviceID, templateID, templateParams);
          this.successMessage = 'Your message has been sent successfully!';
          this.errorMessage = '';
          this.email = '';
          this.question = '';
        } catch (error) {
          console.error('EmailJS error:', error);
          this.errorMessage = 'Failed to send your message. Please try again.';
          this.successMessage = '';
        }
      },
    },
  };
  </script>
  
  
  
  <style scoped>
  .register-container {
    max-width: 400px;
    margin: 20px auto 150px auto;
    padding: 20px;
    background-color: #192734;
    border-radius: 16px;
    color: #d9d9d9;
  }
  
  h2 {
    text-align: center;
    color: #1da1f2;
    margin-bottom: 20px;
  }
  
  .register-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  label {
    color: #8899a6;
    margin-bottom: 5px;
  }
  
  .input-field {
    padding: 12px;
    background-color: #15202b;
    border: 1px solid #38444d;
    border-radius: 10px;
    color: #d9d9d9;
    font-size: 16px;
  }
  
  .input-field::placeholder {
    color: #8899a6;
  }
  
  textarea {
    resize: none;
  }
  
  .submit-button {
    padding: 12px 20px;
    background-color: #1da1f2;
    border: none;
    border-radius: 10px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #0c8ddb;
  }
  
  .error-message {
    color: #e0245e;
    text-align: center;
    margin-top: 10px;
  }
  
  .success-message {
    color: #1da1f2;
    text-align: center;
    margin-top: 10px;
  }
  </style>
  