<template>
  <div class="skiers-container">
    <h1 class="title">All Skiers</h1>
    
    <LoadingSpinner v-if="isLoading" />

    <div v-if="!isLoading && skiers.length" class="skiers-list">
      <div 
        v-for="skier in skiers" 
        :key="skier.id" 
        class="skier-card" 
        @click="navigateToProfile(skier.username)"
      >
        <img :src="skier.avatar || defaultAvatar" alt="Profile Picture" class="skier-avatar" />
        <div class="skier-info">
          <h2 class="skier-username">
            {{ skier.username }}
            <img
              v-if="skier.verified"
              src="@/assets/verified.png"
              alt="Verified"
              class="verified-badge"
              title="Verified Skier"
            />
          </h2>
          <p class="skier-bio">{{ formatBio(skier.bio || '') }}</p>
        </div>
        <!-- Follow/Unfollow Button on the right side -->
        <button
          class="follow-button" 
          v-if="userID != null"
          @click.stop="toggleFollow(skier)"
          @mouseenter="hoveredSkierId = skier.id"
          @mouseleave="hoveredSkierId = null"
          :class="{ 'unfollow': isFollowing(skier.id) }"
        >
          {{ getButtonText(skier.id) }}
        </button>
      </div>
    </div>

    <div v-else-if="!isLoading">
      <p>No skiers found.</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      userID: null, // Store fetched user ID here
      skiers: [],
      defaultAvatar: '/path/to/default/avatar.png', // Set path to default avatar
      isLoading: true, // Add a loading state
      following: [], // Add following list
      hoveredSkierId: null,
      offset: 0, // Track current offset
      limit: 20, // Number of skiers per fetch
      isFetching: false, // Prevent multiple simultaneous fetches
      allLoaded: false, // Track if all skiers are loaded
    };
  },
  async created() {
    await this.fetchUserID();
    await this.fetchFollowing();
    await this.fetchSkiers();
    this.isLoading = false; // Set loading to false after all data is fetched

    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    async fetchUserID() {
      const token = localStorage.getItem('access_token');
      if (token) {
        try {
          const response = await axios.get('https://api.6buoys.com/api/profile/', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          this.userID = response.data.id; // Store userID from API response
        } catch (error) {
          console.error('Error fetching user ID:', error);
        }
      }
    },
    async fetchFollowing() {
      try {
        const response = await axios.get(`https://api.6buoys.com/api/user-following/${this.userID}/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        });

        const followings = response.data;

        this.following = await Promise.all(
          followings.map(async (following) => {
            const profileResponse = await axios.get(`https://api.6buoys.com/api/profile/${following.receiver}/`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
              },
            });
            return profileResponse.data;
          })
        );

        console.log("Full Following Data with Profiles:", this.following);
      } catch (error) {
        console.error('Error fetching following:', error);
      }
    },
    async fetchSkiers() {
      if (this.isFetching || this.allLoaded) return; // Prevent multiple fetches
      this.isFetching = true;
      
      try {
        const response = await axios.get('https://api.6buoys.com/api/profiles/', {
          params: { offset: this.offset, limit: this.limit },
        });

        if (response.data.length < this.limit) {
          this.allLoaded = true; // Mark as all loaded if fewer results are returned
        }

        this.skiers = [...this.skiers, ...response.data]; // Append new skiers to the list
        this.offset += this.limit; // Increment the offset
      } catch (error) {
        console.error('Error fetching skiers:', error);
      } finally {
        this.isFetching = false;
      }
    },
    isFollowing(profileID) {
      return this.following.some((f) => f.id === profileID);
    },
    formatBio(bio) {
      return bio.length > 140 ? bio.substring(0, 140) + '...' : bio;
    },
    getButtonText(skierId) {
      if (this.isFollowing(skierId)) {
        return this.hoveredSkierId === skierId ? 'Unfollow' : 'Following';
      } else {
        return 'Follow';
      }
    },
    async toggleFollow(profile) {
      try {
        if (this.isFollowing(profile.id)) {
          await axios.delete(`https://api.6buoys.com/api/follow-delete/${this.userID}/${profile.id}/`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
          });
          this.following = this.following.filter((f) => f.id !== profile.id);
        } else {
          await axios.post(`https://api.6buoys.com/api/follow-create/${this.userID}/${profile.id}/`, null, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
          });
          this.following.push(profile);
        }
      } catch (error) {
        console.error('Error toggling follow:', error);
      }
    },
    navigateToProfile(username) {
      this.$router.push(`/${username}`);
    },
    handleScroll() {
      const scrollPosition = window.scrollY + window.innerHeight;
      const bottomPosition = document.documentElement.offsetHeight;

      if (scrollPosition >= bottomPosition - 200) { // Load more when 200px near the bottom
        this.fetchSkiers();
      }
    },
  },
};
</script>

<style scoped>
.skiers-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #192734;
  border-radius: 16px;
  color: #d9d9d9;
}

@media screen and (max-width: 600px) {
  .skiers-container {
    padding: 20px 0px 56px 0px;
  }
}

.title {
  text-align: center;
  color: #1da1f2;
  margin-bottom: 20px;
}

.skiers-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.skier-card {
  display: flex;
  position: relative;
  background-color: #15202b;
  padding: 15px;
  border-radius: 12px;
  width: 100%;
  max-width: 350px;
  align-items: flex-start;
  gap: 15px;
  overflow: hidden;
  min-height: 100px;
  cursor: pointer;
}

.skier-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #1da1f2;
  flex-shrink: 0;
}

.skier-info {
  flex-grow: 1;
  min-width: 0;
  padding-right: 70px;
}

.skier-username {
  margin: 0;
  font-size: 1.2em;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 5px;
}

.verified-badge {
  width: 18px;
  height: 18px;
}

.skier-bio {
  margin: 5px 0 0;
  color: #8899a6;
  font-size: 0.9em;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 40px;
  text-align: left;
}

.follow-button {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #1da1f2;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
}

.follow-button.unfollow {
  background-color: transparent;
  border: solid white;
}

.follow-button.unfollow:hover {
  background-color: #ff4757;
  border: solid #ff4757;
  border: none;
}
</style>
