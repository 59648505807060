<template>
  <nav class="navbar" v-show="isNavbarVisible">
    <div class="navbar-container">
      <!-- Wrap logo and brand name with a conditional router-link -->
      <router-link :to="isAuthenticated ? '/timeline' : '/'" class="navbar-brand">
        <img :src="logoSrc" alt="Logo" class="logo" />
        <span class="brand-name">{{ brandName }}</span>
      </router-link>
      <div class="navbar-links">
        <!-- Display user info if authenticated -->
        <div v-if="isAuthenticated" class="navbar-user">
          <!-- Remove post button for small screens -->
          <button v-show="!isMobile" @click="onButtonClick" class="navbar-button">Post</button>
          <div class="dropdown" @click="toggleDropdown">
            <img :src="user.avatar" alt="Profile Picture" class="profile-pic" />
            <span class="username">{{ user.username }}</span>
            <i class="fas fa-caret-down"></i>
            <div v-if="dropdownOpen" class="dropdown-menu">
              <router-link to="/timeline" class="dropdown-item">Timeline</router-link>
              <router-link :to="`/${user.username}`" class="dropdown-item" :key="user.username">My Profile</router-link>
              <router-link to="/all-skiers" class="dropdown-item">All Skiers</router-link>
              <router-link to="/post/create" class="dropdown-item">Create Post</router-link>
             <!-- <div v-if="isWeb">
                <router-link to="/post/create" class="dropdown-item">Tip from Pro</router-link>
              </div> -->
              <router-link to="/contact" class="dropdown-item">Contact</router-link>
              <span @click="logout" class="dropdown-item">Log Out</span>
            </div>
          </div>
        </div>
        <!-- Display "Login" if not authenticated -->
        <div v-else>
          <a href="create-account" class="navbar-link create-account-link">Create Account</a>
          <button @click="goToLogin" class="navbar-button">Login</button>
        </div>
      </div>
    </div>
  </nav>

  <!-- Sticky Bottom Navbar for Mobile (only for signed in users) -->
  <nav v-if="isAuthenticated && isMobile" class="bottom-navbar">
    <router-link to="/timeline" class="bottom-navbar-item">
      <i class="fas fa-home"></i>
      <span>Home</span>
    </router-link>
    <router-link to="/post/create" class="bottom-navbar-item">
      <i class="fas fa-plus-circle"></i>
      <span>Create Post</span>
    </router-link>
  </nav>
</template>

<script>
import axios from 'axios';
import eventBus from '@/utils/eventBus';
import { Capacitor } from '@capacitor/core';

export default {
  name: 'NavBar',
  props: {
    logoSrc: {
      type: String,
      required: true,
    },
    brandName: {
      type: String,
      required: true,
    },
    links: {       
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isAuthenticated: false,
      user: {
        avatar: '',
        username: '',
      },
      dropdownOpen: false,
      lastScrollY: 0,
      isNavbarVisible: true,
      isWeb: false,
      isIOS: false,
      isAndroid: false,
      platformClass: '',
    };
  },
  created() {
    this.checkAuthentication();
    const platform = Capacitor.getPlatform();
    this.isWeb = platform === 'web';
    this.isIOS = platform === 'ios';
    this.isAndroid = platform === 'android';
    this.platformClass = `platform-${platform}`;
    document.body.classList.add(this.platformClass);


    // Listen for the userLoggedIn event
    eventBus.on('userLoggedIn', this.checkAuthentication);

    // Add resize and scroll event listeners
    window.addEventListener('resize', this.checkIfMobile);

    // Initial check
    this.checkIfMobile();
  },
  beforeUnmount() {
    // Clean up event listeners
    window.removeEventListener('resize', this.checkIfMobile);
  },
  methods: {
    async checkAuthentication() {
      const token = localStorage.getItem('access_token');
      if (token) {
        try {
          const response = await axios.get('https://api.6buoys.com/api/profile/', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          this.user = response.data;
          this.isAuthenticated = true;
        } catch (error) {
          console.error('Error fetching user data:', error);
          this.isAuthenticated = false;
        }
      } else {
        this.isAuthenticated = false;
      }
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    logout() {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      this.isAuthenticated = false;
      this.$router.push('/login');
    },
    onButtonClick() {
      this.$router.push('/post/create');
    },
    goToLogin() {
      this.$router.push('/login');
    },
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 600;
    },
  },
};
</script>

<style scoped>
/* General Styles */
body {
  margin: 0;
  background-color: #15202b;
  color: #d9d9d9;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

/* Navbar Styles */
.navbar {
  background-color: #192734;
  padding: 10px 20px;
  border-bottom: 1px solid #38444d;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

/* Safe Area for iOS Devices */
body.platform-ios .navbar {
  @supports (padding: env(safe-area-inset-top)) {
    padding-top: calc(env(safe-area-inset-top) + 60px);
  }
}

/* Visibility transitions */
[v-show="isNavbarVisible"] {
  opacity: 1;
  transform: translateY(0);
}

[v-show="isNavbarVisible"]:not([v-show]) {
  opacity: 0;
  transform: translateY(-100%);
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.navbar-brand {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo {
  width: 40px;
  height: auto;
  margin-right: 10px;
}

.brand-name {
  font-size: 1.5em;
  font-weight: bold;
  color: white;
}

.navbar-links {
  display: flex;
  align-items: center;
}

.navbar-link {
  color: #ffffff;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
  margin-right: 20px;
  transition: color 0.3s ease;
}

.navbar-link:hover {
  color: #1da1f2;
}

/* Media query for small screens */
@media (max-width: 600px) {
  .create-account-link {
    display: none;
  }
}

/* New Navbar Button Styles */
.navbar-button {
  padding: 8px 16px;
  background-color: #1da1f2;
  border: none;
  border-radius: 8px;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.navbar-button:hover {
  background-color: #0c8ddb;
}

/* User Profile and Dropdown Styles */
.navbar-user {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.profile-pic {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  margin-left: 20px;
  border: 2px solid #1da1f2;
}

.username {
  font-size: 1em;
  font-weight: bold;
  color: #ffffff;
}

.dropdown {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.fas.fa-caret-down {
  margin-left: 8px;
  color: #8899a6;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #192734;
  border: 1px solid #38444d;
  border-radius: 8px;
  margin-top: 8px;
  min-width: 150px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdown-item {
  display: block;
  padding: 10px 15px;
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
  border-radius: 8px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #22303c;
}

/* Bottom Navbar for Mobile */
.bottom-navbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #1927346e;
  border-top: 1px solid #38444d;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 8px; /* Reduced padding */
  padding-bottom: 20px;
  transition: background-color 0.3s ease;
  z-index: 1000;
}


.bottom-navbar-item {
  text-align: center;
  color: white;
  text-decoration: none;
  font-size: 1em; /* Reduced font size */
}

.bottom-navbar-item i {
  font-size: 1.2em; /* Reduced icon size */
}

.bottom-navbar-item span {
  display: block;
  font-size: 0.7em; /* Reduced text size */
}
</style>
