<template>
  <div class="tips-list">
    <div v-if="loading" class="loading-tips">
      <LoadingSpinner />
    </div>
    <div v-else-if="tips.length > 0">
      <div v-for="tip in tips" :key="tip.id" class="tip">
        <div class="tip-header">
          <a :href="`/${tip.user?.username}`">
            <img :src="tip.user?.avatar || 'default-avatar.png'" alt="Commenter Profile" class="tip-profile-pic" />
          </a>
          <p class="tip-author">{{ tip.user?.first_name || 'Unknown' }} {{ tip.user?.last_name || '' }}</p>
          <p class="tip-date">{{ formatDate(tip.created) }}</p>
          <i 
            v-if="tip.user?.id === userID" 
            class="fas fa-trash delete-icon" 
            @click="confirmDelete(tip.id)"
            title="Delete Comment"
          ></i>
        </div>
        <p class="tip-body">{{ tip.body }}</p>
        <div class="tip-actions">
          <div class="tip-rate">
            <i
              class="fas fa-minus-circle"
              :class="{ 'voted-down': tip.userVote === -1 }"
              @click="handleVote(tip, -1)"
            ></i>
            <span>{{ tip.ratecount }}</span>
            <i
              class="fas fa-plus-circle"
              :class="{ 'voted-up': tip.userVote === 1 }"
              @click="handleVote(tip, 1)"
            ></i>
          </div>
          <p class="tip-replies" @click="toggleReplies(tip.id)">
            {{ tip.reply_count }} repl<span v-if="tip.reply_count !== 1">ies</span><span v-else>y</span>
          </p>
        </div>

        <!-- Replies Section -->
        <div v-if="tip.showReplies" class="replies-list">
          <div v-if="!tip.replies || tip.replies.length === 0" class="no-replies">
            No replies yet
          </div>
          <div v-else v-for="reply in tip.replies" :key="reply.id" class="reply">
            <div class="reply-header">
              <a :href="`/${reply.user_details?.username}`">
                <img :src="reply.user_details?.avatar || 'default-avatar.png'" alt="Reply Profile" class="reply-profile-pic" />
              </a>
              <p class="reply-author">{{ reply.user_details?.first_name || 'Unknown' }} {{ reply.user_details?.last_name || '' }}</p>
              <p class="reply-date">{{ formatDate(reply.created) }}</p>
              <i 
                v-if="reply.user_details?.id === userID" 
                class="fas fa-trash delete-icon" 
                @click="confirmReplyDelete(reply.id)" 
                title="Delete Reply"
              ></i>
            </div>
            <p class="reply-body">{{ reply.body }}</p>
          </div>
          <!-- Add a form to create a new reply -->
          <div class="new-reply-form">
            <input 
              type="text" 
              v-model="newReply[tip.id]" 
              placeholder="Reply to this tip" 
              @keyup.enter="createReply(tip.id)"
            />
            <button @click="createReply(tip.id)">Send</button>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="no-tips">
      <p>No tips yet - be the first to comment!</p>
    </div>
    <!-- Add v-if to conditionally show the form -->
    <div v-if="showNewTipForm" class="new-tip-form">
      <textarea 
        v-model="newTip" 
        placeholder="Create a waterski tip"
        rows="1"
      ></textarea>
      <button @click="createTip">Send</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
  name: 'WaterskiTips',
  components: {
    LoadingSpinner
  },
  props: {
    postId: {
      type: Number,
      required: true
    },
    initialTips: {
      type: Array,
      default: () => []
    },
    showNewTipForm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tips: [],
      newTip: '',
      newReply: {},
      userVotes: {},
      userID: null,
      loading: true,
      loadingTips: {}
    };
  },
  created() {
    this.tips = this.initialTips;
    this.getUserID();
    if (this.initialTips.length === 0) {
      this.fetchTips();
    } else {
      this.loading = false;
    }
  },
  methods: {
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    async getUserID() {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get('https://api.6buoys.com/api/profile/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.userID = response.data.id;
        await this.fetchUserVotes();
      } catch (error) {
        console.error('Error fetching user ID:', error);
      }
    },
    async fetchUserVotes() {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get('https://api.6buoys.com/api/user-comment-rates/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.userVotes = response.data.reduce((acc, vote) => {
          acc[vote.comment] = { rate: vote.rate, rateId: vote.id };
          return acc;
        }, {});
      } catch (error) {
        console.error('Error fetching user votes:', error);
      }
    },
    async fetchTips() {
      this.loading = true;
      try {
        const token = localStorage.getItem('access_token');
        
        // First ensure we have the user votes
        if (Object.keys(this.userVotes).length === 0) {
          await this.fetchUserVotes();
        }

        const response = await axios.get(`https://api.6buoys.com/api/post-comments/${this.postId}/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        this.tips = await Promise.all(response.data.map(async tip => ({
          ...tip,
          userVote: this.userVotes[tip.id]?.rate || 0,  // This will now have the correct vote
          replies: [],
          reply_count: await this.fetchReplyCount(tip.id),
          showReplies: false,
        })));
      } catch (error) {
        console.error('Error fetching tips:', error);
      } finally {
        this.loading = false;
      }
    },
    async createTip() {
      if (!this.newTip.trim()) return;

      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.post(
          'https://api.6buoys.com/api/comment-create/',
          { post: this.postId, body: this.newTip },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.newTip = '';
        // Add the new tip to the tips array
        const newTip = {
          ...response.data,
          userVote: 0,
          replies: [],
          reply_count: 0,
          showReplies: false
        };
        this.tips.push(newTip); // Add to end of array
        this.$emit('update:tips-count', this.tips.length);
      } catch (error) {
        console.error('Error creating tip:', error);
      }
    },
    async handleVote(tip, value) {
      const currentVote = tip.userVote || 0;

      try {
        const token = localStorage.getItem('access_token');
        const rateId = this.userVotes[tip.id]?.rateId;

        if (currentVote !== 0 && currentVote !== value) {
          await axios.delete(`https://api.6buoys.com/api/rate-delete/${rateId}/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          this.updateTipVotes(tip, currentVote, 0);
        }

        if (currentVote !== value) {
          const requestData = { comment: tip.id, rate: value, user: this.userID };
          let response;

          if (rateId && currentVote !== 0) {
            response = await axios.post(
              `https://api.6buoys.com/api/rate-update/${rateId}/`,
              requestData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          } else {
            response = await axios.post(
              'https://api.6buoys.com/api/rate-create/',
              requestData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          }

          this.userVotes[tip.id] = {
            rateId: response.data.id,
            rate: value,
          };
          this.updateTipVotes(tip, 0, value);
        } else if (currentVote === value) {
          await axios.delete(`https://api.6buoys.com/api/rate-delete/${rateId}/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          this.updateTipVotes(tip, currentVote, 0);
        }
      } catch (error) {
        console.error('Error handling vote:', error);
      }
    },
    updateTipVotes(tip, currentVote, newVote) {
      if (newVote === 0) {
        tip.ratecount -= currentVote;
        tip.userVote = 0;
        delete this.userVotes[tip.id];
      } else {
        tip.ratecount += newVote - currentVote;
        tip.userVote = newVote;
        this.userVotes[tip.id] = { ...this.userVotes[tip.id], rate: newVote };
      }
    },
    async confirmDelete(commentId) {
      if (confirm('Are you sure you want to delete this comment?')) {
        try {
          const token = localStorage.getItem('access_token');
          await axios.delete(`https://api.6buoys.com/api/comment-delete/${commentId}/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          this.tips = this.tips.filter(tip => tip.id !== commentId);
          this.$emit('update:tips-count');
        } catch (error) {
          console.error('Error deleting comment:', error);
        }
      }
    },
    async confirmReplyDelete(replyId) {
      if (confirm('Are you sure you want to delete this reply?')) {
        try {
          const token = localStorage.getItem('access_token');
          await axios.delete(`https://api.6buoys.com/api/reply-delete/${replyId}/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          this.removeReplyFromTip(replyId);
        } catch (error) {
          console.error('Error deleting reply:', error);
        }
      }
    },
    removeReplyFromTip(replyId) {
      this.tips.forEach(tip => {
        if (tip.replies) {
          tip.replies = tip.replies.filter(reply => reply.id !== replyId);
          tip.reply_count = tip.replies.length;
        }
      });
    },
    async fetchReplyCount(tipId) {
      try {
        const response = await axios.get(`https://api.6buoys.com/api/comment-reply-count/${tipId}/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        });
        return response.data.count;
      } catch (error) {
        console.error('Error fetching reply count:', error);
        return 0;
      }
    },
    async createReply(tipId) {
      if (!this.newReply[tipId] || !this.newReply[tipId].trim()) return;

      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.post(
          'https://api.6buoys.com/api/reply-create/',
          {
            comment: tipId,
            body: this.newReply[tipId],
            user: this.userID,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.id) {
          this.newReply[tipId] = '';
          const tip = this.tips.find(t => t.id === tipId);
          if (tip) {
            if (!tip.replies) tip.replies = [];
            tip.replies.push(response.data);
            tip.reply_count = (tip.reply_count || 0) + 1;
          }
        }
      } catch (error) {
        console.error('Error creating reply:', error);
      }
    },
    async toggleReplies(tipId) {
      const tip = this.tips.find(t => t.id === tipId);
      if (!tip) return;

      tip.showReplies = !tip.showReplies;

      if (tip.showReplies && (!tip.replies || tip.replies.length === 0)) {
        try {
          const response = await axios.get(
            `https://api.6buoys.com/api/comment-replies/${tipId}/`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
              },
            }
          );
          tip.replies = response.data;
        } catch (error) {
          console.error('Error fetching replies:', error);
          tip.replies = [];
        }
      }
    },
  }
};
</script>

<style scoped>
.tips-list {
  padding: 10px 4px;
  background-color: #15202b;
}

.tip {
  padding: 12px;
  border-bottom: 1px solid #38444d;
  background-color: #192734;
  border-radius: 8px;
  margin-bottom: 10px;
}

.tip-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.tip-profile-pic {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.tip-author {
  font-weight: bold;
  color: #ffffff;
  margin: 0;
}

.tip-date {
  font-size: 0.8em;
  color: #8899a6;
  margin-left: auto;
}

.delete-icon {
  color: #e0245e;
  cursor: pointer;
  margin-left: 10px;
  transition: color 0.2s;
}

.delete-icon:hover {
  color: #ff5a78;
}

.tip-body {
  color: white;
  text-align: left;
}

.tip-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.tip-rate {
  display: flex;
  align-items: center;
  gap: 8px;
  color: white;
}

.tip-rate i {
  cursor: pointer;
  transition: color 0.2s;
}

.tip-rate .voted-up {
  color: #1da1f2;
}

.tip-rate .voted-down {
  color: #e0245e;
}

.tip-replies {
  font-size: 0.9em;
  color: #8899a6;
  cursor: pointer;
  transition: color 0.2s;
}

.tip-replies:hover {
  color: #1da1f2;
}

.new-tip-form {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.new-tip-form textarea {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  flex-grow: 1;
  padding: 8px 12px;
  border-radius: 20px;
  border: 1px solid #38444d;
  background-color: #192734;
  color: #e1e8ed;
  margin-right: 10px;
  font-size: 16px;
  height: 20px;
  transition: height 0.3s ease;
  resize: none;
  text-align: left;
  line-height: normal;
}

.new-tip-form textarea:focus {
  height: 100px;
}

.new-tip-form input::placeholder {
  color: #8899a6;
}

.new-tip-form button {
  padding: 8px 16px;
  background-color: #1da1f2;
  color: #ffffff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.new-tip-form button:hover {
  background-color: #0c8ddb;
}

.replies-list {
  margin-top: 10px;
  padding-left: 20px;
  border-left: 2px solid #38444d;
}

.reply {
  padding: 8px;
  border-bottom: 1px solid #38444d;
  background-color: #15202b;
  border-radius: 8px;
  margin-bottom: 8px;
  text-align: left;
}

.reply-header {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.reply-profile-pic {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
}

.reply-author {
  font-weight: bold;
  color: #ffffff;
  margin: 0;
}

.reply-date {
  font-size: 0.8em;
  color: #8899a6;
  margin-left: auto;
}

.new-reply-form {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.new-reply-form input {
  flex-grow: 1;
  padding: 6px 10px;
  border-radius: 15px;
  border: 1px solid #38444d;
  background-color: #192734;
  color: #e1e8ed;
  margin-right: 8px;
}

.new-reply-form button {
  padding: 6px 12px;
  background-color: #1da1f2;
  color: #ffffff;
  border: none;
  border-radius: 15px;
  cursor: pointer;
}

.new-reply-form button:hover {
  background-color: #0c8ddb;
}

.loading-tips {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.no-tips {
  text-align: center;
  padding: 20px;
  color: #8899a6;
}
</style> 