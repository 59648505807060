<template>
    <div class="request-pro-tip-container">

      <!-- STEP 1: Select a Pro -->
      <div v-if="step === 1" class="step-container">
        <h1>Select a Pro</h1>
        <p>Choose from a list of verified skiers:</p>
        
        <LoadingSpinner v-if="loadingPros" />
        <ul v-else class="pro-list">
          <li 
            v-for="pro in pros" 
            :key="pro.id" 
            class="pro-item"
            @click="selectPro(pro)"
            :class="{ selected: selectedPro && selectedPro.id === pro.id }"
          >
            <img 
              :src="pro.avatar || defaultAvatar" 
              class="pro-avatar" 
              alt="Pro Avatar"
            />
            <span class="pro-username">
              {{ pro.username }}
              <img
                v-if="pro.verified"
                src="@/assets/verified.png"
                alt="Verified"
                class="verified-badge"
              />
            </span>
            <!-- Tip Price + Currency -->
            <span class="pro-tip">
                <!-- If tipprice is not null, show it; otherwise handle fallback -->
                {{ formatTipPrice(pro.tipprice, pro.currency) }}
            </span>
          </li>
        </ul>
        <button
          class="step-button"
          :disabled="!selectedPro"
          @click="goToStep(2)"
        >
          Next
        </button>
      </div>
  
      <!-- STEP 2: Select One of Your Own Posts -->
      <div v-else-if="step === 2" class="step-container">
        <h1>Select Your Post</h1>
        <p>Choose one of your posts for which you want a pro tip:</p>
  
        <LoadingSpinner v-if="loadingMyPosts" />
        <ul v-else class="my-posts-list">
          <li 
            v-for="post in myPosts" 
            :key="post.id" 
            class="my-post-item"
            @click="selectPost(post)"
            :class="{ selected: selectedPost && selectedPost.id === post.id }"
          >
            <!-- Example preview of post -->
            <img v-if="post.image" :src="post.image" class="post-thumbnail" />
            <video
              v-if="post.video"
              :src="post.video"
              muted
              autoplay
              loop
              playsinline
              class="post-thumbnail"
            ></video>
            <p>{{ post.content }}</p>
          </li>
        </ul>
        <button
          class="step-button"
          :disabled="!selectedPost"
          @click="goToStep(3)"
        >
          Next
        </button>
      </div>
  
      <!-- STEP 3: Payment / Checkout -->
        <div v-show="step === 3" class="step-container"> 
        <h1>Checkout</h1>
        <p>{{ selectedPro?.username }} has 7 days to deliver your coaching tip. No charge if it’s not delivered on time.</p>

        <div class="summary-container">
            <p><strong>Pro:</strong> {{ selectedPro?.username }}</p>
            <p><strong>Post:</strong> {{ selectedPost?.content }}</p>
            <p><strong>Tip Amount:</strong> {{ selectedPro?.tipprice }}</p>

        </div>

        <!-- 1. Placeholder for Card Element -->
        <div class="payment-icons">
            <img :src="require('@/assets/paymenticons/1.png')" alt="Visa" class="payment-icon" />
            <img :src="require('@/assets/paymenticons/2.png')" alt="MasterCard" class="payment-icon" />
            <img :src="require('@/assets/paymenticons/22.png')" alt="AmericanExpress" class="payment-icon" />
        </div>
        <div id="card-element"></div>
        <!-- 2. Error message container -->
        <div id="card-errors" class="payment-error"></div>

        <button 
            class="pay-button"
            :disabled="paymentLoading || !amount"
            @click="handlePayment"
        >
            Pay
        </button>

        <!-- Payment Status Messages -->
        <LoadingSpinner v-if="paymentLoading" />
        <div v-if="paymentSuccess" class="payment-success">
            Payment Intent created successfully. 
            <br /> Payment Intent ID: {{ paymentIntentId }}
        </div>
        <div v-if="paymentError" class="payment-error">
            Error: {{ paymentError }}
        </div>
    </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import LoadingSpinner from "@/components/LoadingSpinner.vue";
  import { loadStripe } from "@stripe/stripe-js";
  
  
  export default {
    name: "RequestProTip",
    components: {
      LoadingSpinner,
    },
    data() {
      return {
        step: 1,
        pros: [],
        myPosts: [],
        selectedPro: null,
        selectedPost: null,
        userID: null,
        userName: null,
        
        amount: null,
        selectedCurrency: "USD", // default
        loadingPros: false,
        loadingMyPosts: false,
        paymentLoading: false,
        paymentSuccess: false,
        paymentError: null,
        paymentIntentId: null,
        paymentMethodId: null,
  
        defaultAvatar: "https://6buoys-bucket.s3.amazonaws.com/avatar.png", // or your local fallback
        stripe: null,       // will hold Stripe instance
        cardElement: null,
      };
    },
    computed: {
      // Add logic to get user ID from store or prop if you prefer:
      userToken() {
        return localStorage.getItem("access_token");
      },
    },
    async created() {
      await this.fetchUserID();
      await this.fetchVerifiedPros();
      await this.fetchMyPosts();
    },
    async mounted() {
        // loadStripe with your Publishable key
        this.stripe = await loadStripe("pk_test_51Qb1kGIcRInt3CpooSNu14oUMumRuOb8NWcSD6BQWq4HTrTBRW2Ye1uHbGH1HjtXIk89zTozcJWTTgemmpWxjT9C00CaWvAIbx"); // Replace with your real publishable key

        // Create a Stripe Elements instance
        const elements = this.stripe.elements();

        // Create a Card Element and mount it
        this.cardElement = elements.create("card", {
        style: {
            base: {
            color: "#fff",
            fontSize: "16px",
            "::placeholder": {
                color: "#ccc",
            },
            },
        },
        hidePostalCode: true,
        });
        this.cardElement.mount("#card-element");
    },
    methods: {
      async fetchVerifiedPros() {
        this.loadingPros = true;
        try {
          const response = await axios.get("https://api.6buoys.com/api/profiles/", {
            headers: {
              Authorization: `Bearer ${this.userToken}`,
            },
            // If your backend allows a query param or filter to only get verified users:
            params: { verified: true },
          });
          this.pros = response.data.filter(pro => pro.verified); //this.pros.filter(pro => pro.verified)
        } catch (error) {
          console.error("Error fetching verified pros:", error);
        } finally {
          this.loadingPros = false;
        }
      },

      async fetchUserID() {
        const token = localStorage.getItem('access_token');
        if (token) {
            try {
            const response = await axios.get('https://api.6buoys.com/api/profile/', {
                headers: {
                Authorization: `Bearer ${token}`,
                },
            });
            this.userID = response.data.id; // Store userID from API response
            this.userName = response.data.username;
            } catch (error) {
            console.error('Error fetching user ID:', error);
            }
        }
    },
  
    async fetchMyPosts() {
        this.loadingMyPosts = true;
        try {
            // Ensure you have the userId from the signed-in user's data
            const userId = this.userID; // Replace with your actual method to retrieve the userId
            
            if (!userId) {
            throw new Error("User ID is not available");
            }

            const response = await axios.get(`https://api.6buoys.com/api/posts/${userId}/`, {
            headers: {
                Authorization: `Bearer ${this.userToken}`,
            },
            });
            this.myPosts = response.data;
        } catch (error) {
            console.error("Error fetching your posts:", error);
        } finally {
            this.loadingMyPosts = false;
        }
        },

  
        selectPro(pro) {
            this.selectedPro = pro;
            // If the pro has a tip price / currency, auto-set in the data
            if (pro.tipprice) {
                this.amount = pro.tipprice;
            } else {
                this.amount = null; // no tip price set
            }
            if (pro.currency) {
                this.selectedCurrency = pro.currency;
            } else {
                this.selectedCurrency = "USD"; // fallback
            }
        },
  
      selectPost(post) {
        this.selectedPost = post;
      },
  
      goToStep(stepNumber) {
        this.step = stepNumber;
      },
  
      async handlePayment() {
        this.paymentLoading = true;
        this.paymentSuccess = false;
        this.paymentError = null;

        try {
            // (A) Create PaymentMethod from the card details
            const { paymentMethod, error } = await this.stripe.createPaymentMethod({
            type: "card",
            card: this.cardElement,
            billing_details: {
                // Optionally collect user info
                name: "Test Name",
                email: "test@example.com"
            },
            });

            if (error) {
            // If there's an error creating the PaymentMethod,
            // show it and stop
            console.error("Error creating payment method:", error);
            this.paymentError = error.message;
            this.paymentLoading = false;
            return;
            }

            // If PaymentMethod was successfully created,
            // store the ID in our component
            this.paymentMethodId = paymentMethod.id;

            // (B) Now create the Payment Intent on the back end
            await this.createPaymentIntent();
        } catch (err) {
            console.error("Payment error:", err);
            this.paymentError = err?.message || "An error occurred.";
        } finally {
            this.paymentLoading = false;
        }
        },

        // 4. Our existing createPaymentIntent method
        async createPaymentIntent() {
        // We already have a PaymentMethod ID in `this.paymentMethodId`
        this.paymentSuccess = false;
        this.paymentError = null;
        this.paymentIntentId = null;

        try {
            const bodyData = {
            pro_id: this.selectedPro?.id,
            post_id: this.selectedPost?.id,
            amount: this.amount, // e.g. "100.00"
            paymentMethodId: this.paymentMethodId,
            };

            const response = await axios.post(
            "https://api.6buoys.com/payments/create-intent/",
            bodyData,
            {
                headers: {
                Authorization: `Bearer ${this.userToken}`,
                },
            }
            );

            this.paymentIntentId = response.data.paymentIntentId;
            this.paymentSuccess = true;
 
            await this.stripe.confirmCardPayment(response.data.clientSecret);

            // Redirect to the new link
            const name = this.userName;
            this.$router.push({
                path: `/${name}?viewType=paidTipsFromPros`,
                query: { viewType: 'paidTipsFromPros' }
            });


        } catch (error) {
            console.error("Error creating payment intent:", error);
            this.paymentError =
            error.response?.data?.error || "Failed to create payment intent.";
        }
        },
      // Helpers
        formatTipPrice(value, currency) {
        if (value == null) {
            return "No tip price set";
        }
        // Basic formatting; adjust or localize as you wish
        return `${value} ${currency || "USD"}`;
        },
    },
  };
  </script>
  
  <style scoped>
  .request-pro-tip-container {
    max-width: 600px;
    margin: 20px auto;
    background-color: #192734;
    padding: 20px;
    border-radius: 12px;
    color: #d9d9d9;
  }
  
  .step-container {
    margin-bottom: 30px;
  }
  
  h1 {
    text-align: center;
    color: #1da1f2;
  }
  
  h2 {
    margin-bottom: 10px;
    color: #fff;
    font-size: 16px;
  }
  
  .pro-list, .my-posts-list {
    list-style-type: none;
    padding: 0;
    margin: 10px 0 20px 0;
  }
  
  .pro-item, .my-post-item {
    display: flex;
    align-items: center;
    background-color: #15202b;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .pro-item:hover, .my-post-item:hover {
    background-color: #22303c;
  }
  
  .pro-item.selected, .my-post-item.selected {
    border: 2px solid #1da1f2;
  }
  
  .pro-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }
  
  .pro-username {
    color: #ffffff;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .verified-badge {
    width: 16px;
    height: 16px;
  }

  .pro-tip {
  margin-left: auto;
  color: #1da1f2;
  font-weight: bold;
}
  
  .post-thumbnail {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 10px;
    flex-shrink: 0;
  }
  

  .summary-container {
    background-color: #15202b;
    border-radius: 12px;
    padding: 12px;
  }
  
  #tip-amount {
    width: 100px;
    margin-left: 10px;
    text-align: right;
    color: white;
  }
  
  .step-button, .pay-button {
    background-color: #1da1f2;
    color: #ffffff;
    border: none;
    padding: 10px 16px;
    border-radius: 8px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .step-button:disabled, .pay-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  #card-element {
    background-color: #15202b;
    border-radius: 8px;
    padding: 10px 12px;
    border: 1px solid #ced4da;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    color: #495057;
}

#card-element:focus {
    border-color: #80bdff;
    box-shadow: 0 0 5px rgba(128, 189, 255, 0.5);
}

.StripeElement--webkit-autofill {
    background-color: #f4f8fa !important;
}

.payment-icons {
    display: flex;
    align-items: center;
    justify-content: center; /* Centers the icons horizontally */
    gap: 10px;
    margin: 20px;
}

.payment-icon {
    height: 24px;
    width: auto;
}

  
  .payment-success {
    color: #2ecc71;
    margin-top: 10px;
  }
  
  .payment-error {
    color: #e74c3c;
    margin-top: 10px;
  }

  #card-errors {
    margin-top: 8px;
    color: #e3342f;
    font-size: 14px;
}

  </style>
  