<template>
    <div class="paid-tips">
      <!-- Section 1: Request a Tip -->
      <section class="request-tip">
        <h1 class="title">Request a Coaching Tip from a Pro</h1>
        <p class="subtitle">Choose from a list of verified PRO skiers and pay to request a personalized tip.</p>
        <div class="pro-list">
          <div class="pro-item" v-for="pro in pros" :key="pro.id">
            <img :src="pro.image" alt="Pro Image" class="pro-image" />
            <div class="pro-details">
              <span class="pro-name">{{ pro.name }}</span>
              <span class="verified-badge"><i class="fas fa-check-circle"></i></span>
            </div>
            <span class="pro-price">{{ pro.price ? `${pro.price} ${pro.currency}` : 'No tip price set' }}</span>
          </div>
        </div>
        <router-link
            to="/login"
            class="login-button"
            >
            Login
        </router-link>
      </section>
  </div>
  <div class="paid-tips">
      <!-- Section 2: Verification Request -->
      <section class="verification-request">
        <h1 class="title">Are You a Pro Skier?</h1>
        <p class="subtitle">
          If you're a pro skier but not verified yet, request verification below. After verification, you can set your tip price and start getting paid for your tips.
        </p>
        <router-link
            to="/contact"
            class="request-verification-button"
            >
            Request Verification
        </router-link>
      </section>
    </div>
    <FooterPublic />
  </template>
  
  <script>
  import FooterPublic from "@/components/FooterPublic.vue";

  export default {
    name: "PaidTips",
    components: {
        FooterPublic,
    },
    data() {
      return {
        pros: [
          { id: 2, name: "Jodifisher", price: "30.00", currency: "USD", image: "https://6buoys-bucket.s3.amazonaws.com/avatars/image.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAR4AP6NRIP2IJ2HEU%2F20241230%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20241230T130744Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=333c0f0d5dacc46bccd768b34c7abbcdf5107fa06621d6acd89d9fb36e45d68f" },
          { id: 3, name: "Julien", price: "30.00", currency: "USD", image: "https://6buoys-bucket.s3.amazonaws.com/avatars/A3A7DFD4-5215-4662-9DD1-741494711501.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAR4AP6NRIP2IJ2HEU%2F20241230%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20241230T130744Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=a2574dd681dcec592d7aa71e91dce3b8c5f0baa6133d5006c7667cf4a2056613" },
          { id: 4, name: "freekschool", price: "30.00", currency: "EUR", image: "https://6buoys-bucket.s3.amazonaws.com/avatar.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAR4AP6NRIP2IJ2HEU%2F20241230%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20241230T130744Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=ce9fefa58799eba44f827e61a5a43cc9e96e72efeffb25b6d60340caae788364" },
        ],
      };
    },
  };
  </script>
  
  <style scoped>
  .paid-tips {
    max-width: 600px;
    margin: 0 auto;
    padding: 40px;
    background-color: #192734;
    border-radius: 12px;
    color: #d9d9d9;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .title {
    font-size: 1.8rem;
    color: #1da1f2;
    margin-bottom: 10px;
    margin-top: 0px;
  }
  
  .subtitle {
    font-size: 1rem;
    color: #ffffff;
    margin-bottom: 20px;
  }
  
  .pro-list {
    margin: 20px 0;
    padding: 10px;
    background-color: #192734;
    border-radius: 12px;
  }
  
  .pro-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #38444d;
  }
  
  .pro-item:last-child {
    border-bottom: none;
  }
  
  .pro-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #1da1f2;
  }
  
  .pro-details {
    flex-grow: 1;
    margin-left: 10px;
    text-align: left;
  }
  
  .pro-name {
    font-size: 1rem;
    font-weight: bold;
    color: #ffffff;
  }
  
  .verified-badge {
    color: #1da1f2;
    font-size: 1rem;
    margin-left: 5px;
  }
  
  .pro-price {
    font-size: 1rem;
    color: #1da1f2;
  }
  
  .login-button,
  .request-verification-button {
    padding: 10px 20px;
    font-size: 1rem;
    color: #ffffff;
    background-color: #1da1f2;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
    text-decoration: none;
    margin-top: 20px;
  }
  
  .next-button:hover,
  .request-verification-button:hover {
    background-color: #0c8ddb;
    text-decoration: none;
  }
  


  
  </style>
  